export function detectBrowser() {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
        browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
        browserName = 'edge';
    } else {
        browserName = 'undetected';
    }

    return browserName;
}

export function getReturnUrl(client) {
    try {
        const clientDomainMap = JSON.parse(process.env.REACT_APP_CLIENT_DOMAIN_MAP);
        if (client in clientDomainMap) {
            return clientDomainMap[client];
        }
        console.warn(`client not found in env var REACT_APP_CLIENT_DOMAIN_MAP: ${process.env.REACT_APP_CLIENT_DOMAIN_MAP}`, clientDomainMap);
    } catch (error) {
        console.warn(`error parsing env var REACT_APP_CLIENT_DOMAIN_MAP: ${process.env.REACT_APP_CLIENT_DOMAIN_MAP}`, error);
    }

    return window.location.origin;
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
