import React, { useState } from 'react';
import { Dialog as MuiDialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

function Dialog(props) {
    const {open, onClose, title, text, onConfirm, confirmText, cancelText} = props;
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        if (!onConfirm) {
            return;
        }
        setIsLoading(true);
        try {
            await onConfirm();
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        if (!isLoading) {
            onClose();
        }
    };

    return (
        <MuiDialog
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={isLoading}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            {text && (
                <DialogContent>
                    <DialogContentText>{text}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Grid container spacing={0.5} justifyContent={{sm: 'end'}}>
                    <Grid item xs={12} sm={'auto'}>
                        <Button
                            variant={'contained'}
                            disableElevation={true}
                            color={'primary'}
                            disabled={isLoading}
                            onClick={handleClose}>
                            {t(cancelText ? cancelText : 'customerportal.dialog.cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={'auto'}>
                        <LoadingButton
                            variant={'contained'}
                            disableElevation={true}
                            color={'secondary'}
                            onClick={handleConfirm}
                            loading={isLoading}>
                            {t(confirmText ? confirmText : 'customerportal.dialog.confirm')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </MuiDialog>
    );
}

export default Dialog;
