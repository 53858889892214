import React, {useEffect, useState} from 'react';
import PageWrapper from "../utils/PageWrapper";
import {
    Button,
    FormControl,
    FormControlLabel,
    Box,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    Collapse
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";
import {useDispatch, useSelector} from "react-redux";
import {changeClient} from "../../redux/actions/azure";
import {resetChooseClient} from "../../redux/slices/azure";
import {Navigate} from "react-router-dom";
import Dialog from "../layout/Dialog";
import {getReturnUrl} from "../../utils/pageFunctions";

async function handleSubmit(value, dispatch, setSubmitted, instance) {
    if (value) {
        const postLogoutRedirectUri = getReturnUrl(value);
        await dispatch(changeClient({
            client: value,
            postLogoutRedirectUri: postLogoutRedirectUri,
            instance
        }));
        setSubmitted(true);
    }
}

function ChooseClient(props) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const clientShouldRedirect = useSelector((state) => state.azure.clientShouldRedirect);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const {instance, accounts} = useMsal();

    useEffect(() => {
        if (!value && accounts && accounts[0]) {
            if (accounts[0].idTokenClaims.extension_Mandant) {
                setValue(accounts[0].idTokenClaims.extension_Mandant);
            }

            if (accounts[0].idTokenClaims.extension_Mandanten) {
                setOptions(accounts[0].idTokenClaims.extension_Mandanten?.split('|'));
            }
        }
        // eslint-disable-next-line
    }, [accounts]);

    useEffect(() => {
        if (clientShouldRedirect) {
            dispatch(resetChooseClient());
        }
        // eslint-disable-next-line
    }, []);

    if (clientShouldRedirect && submitted) {
        return <Navigate to={`/${i18n.language}/dashboard`} replace/>
    } else {
        return (
            <PageWrapper siteTitle={'customerportal.navigation.choose_client'}>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Typography sx={{
                            marginBottom: 3
                        }}>
                            {t('customerportal.choose_client.description')}
                        </Typography>
                        <Collapse in={!!(accounts && (options && options.length > 0))}>
                            <Box sx={{
                                marginBottom: 2.5
                            }}>
                                {(options && options.length > 0) &&
                                    <FormControl>
                                        <RadioGroup value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                        >
                                            {options.map((option, i) => (
                                                <FormControlLabel key={i} value={option} control={<Radio/>}
                                                                  label={option}/>
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                }
                            </Box>
                            <Button variant={"contained"}
                                    disableElevation={true}
                                    color={"secondary"}
                                    onClick={() => setAlertOpen(true)}
                            >
                                {t('customerportal.choose_client.submit')}
                            </Button>
                            <Dialog open={alertOpen}
                                    onClose={() => setAlertOpen(false)}
                                    text={t('customerportal.choose_client.dialog_text')}
                                    onConfirm={() => handleSubmit(value, dispatch, setSubmitted, instance)}/>
                        </Collapse>
                    </Grid>
                </Grid>
            </PageWrapper>
        )
    }
}

export default ChooseClient;
